const data = [
  {
    date: "07/17/2023",
    description:
      "meeting for bureau, about AMPC, and the website features All members should be present!!",
    time: "20:00",
    title: "Weekly summer meeting",
    type: "meeting",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "07/17/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "meeting",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "07/17/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "other",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "07/19/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "meeting",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "07/19/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "meeting",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "07/21/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "competition",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "08/2/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "competition",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "08/6/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "competition",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "08/8/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "competition",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
  {
    date: "08/8/2023",
    description: "a random meeting",
    time: "22:40",
    title: "this is a test one",
    type: "course",
    valid: true,
    writer: "3VHoaYNmBUNYkp0Wl53abbFaBg12",
    pv: "",
  },
];

export function getData() {
  return data;
}
